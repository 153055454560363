import React from 'react'
import { useStaticQuery, graphql} from 'gatsby'
import Layout from '../components/layout'
import { Container, Grid, Text, Box, Heading } from 'theme-ui'

import { StaticImage } from "gatsby-plugin-image"
import './bio.css'

export const Head = () => (
  <>
    <title>Meet Deb</title>
    <meta name="description" content="Bio Page" />
  </>
)

const Bio = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      craft {
        entries(enabledForSite: true, section: "bio") {
          ... on CRAFT_bio_bio_Entry {
            id
            body
            title
            credentials {
              credential
            }
          }
        }
      }
    }
  `)
  return (
    <Layout variant="sidebar">
      <Container variant="fluid">
        <Grid
          gap={[2, 2, 3, 1]}
          row={[2, 2, 1, 1]}
          columns={[1, 1, 1, 2]}
          sx={{
            backgroundColor: ['white', 'transparent'],
            display: 'relative',
            paddingBottom: '5em',
          }}
        >
          <Box
            sx={{
              height: ['500px', '500px', '500px', '100vh'],
              overflow: 'hidden',
              position: 'relative',
              display: 'block',
              width: '100%',
            }}
          >
            <Box
              sx={{
                height: ['100vh', '100vh', '150vh'],
                borderRadius: '0px',
                border: ['none'],
                overflow: 'hidden',
                position: 'fixed',
                display: 'block',
                width: ['100%', '100%', '100%', '50%'],
                margin: [0, '0'],
                top: 0,
              }}
            >
              <div style={{
                display: "grid",
                width: "100%",
                height: "100vh",
                marginTop: '50px',
                backgroundColor: "blue",
              }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
        }}
        layout="constrained"
        aspectRatio={1 / 1}
        alt=""
        src={
          "../images/bio/deb-in-nature.png"
        }
        formats={["auto", "webp", "avif"]}
      />
      </div>
            </Box>
          </Box>
          <Box
            sx={{
              margin: ['-4em 1em', '2em', '2em', '0em'],
              backgroundColor: ['background', 'none'],
              zIndex: 12,
              borderRadius: '10px',
              padding: ['3em', '5em 6em', '5em 7em', '5em'],
              '@media only screen and (max-width: 500px)': { padding: '2em 1.5em', margin: '-6em 0' }
            }}
          >
            <Heading as="h1" variant="h1" className='soft-faux-font'>
              Meet Deb
            </Heading>
            <Text
              variant="paragraph"
              dangerouslySetInnerHTML={{ __html: data.craft.entries[0].body }}
            />
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Bio
